const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  GOOGLE_SIGNIN_START: "GOOGLE_SIGNIN_START",
  EMAIL_SIGNIN_START: "EMAIL_SIGNIN_START",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "SIGNIN_FAILURE",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SIGNOUT_START: "SIGNOUT_START",
  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_FAILURE: "SIGNOUT_FAILURE",
  SIGNUP_START: "SIGNUP_START",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
};
export default UserActionTypes;
