import styled from "styled-components";

export const CollectionPreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
`
export const TitleText = styled.h1`
    font-size: 28px;
    margin-bottom: 25px;
    cursor: pointer;

    &:hover {
        color: grey;
    }
`
export const PreviewContainer = styled.div`
    display: flex;
    justify-content: space-between;
`